.otherRow {
  padding: 0px !important;
}

.headerRow {
  padding: 0px !important;
  padding-top: 20px !important;
}

.crimeButton {
  width: 250px;
  border: 1px darkgray solid !important;
}

.resetButton {
  margin-top: 20px !important;
}

.topHeader {
  margin-bottom: 0px !important;
  text-decoration: underline;
}

.detailHeader {
  margin-top: 10px !important;
}

.explanationList {
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}
