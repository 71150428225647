.App {
  font-family: sans-serif;
  text-align: center;
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 30px;
  height: 400px;
  display: inline-block;
  margin-right: 10px;
  border: 1px darkgray solid;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  background-color: #2196f3;
  box-shadow: none;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
